import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import EASA from "../assets/CAMO/easa-card.svg";
import HighlyExperienced from "../assets/CAMO/highly-experienced.svg";
import Delivery from "../assets/CAMO/icon-camo-asset-management.svg";
import FleetStatus from "../assets/CAMO/icon-camo-fleet-status.svg";
import Maintenance from "../assets/CAMO/icon-camo-maintenance.svg";
import Monitoring from "../assets/CAMO/icon-camo-monitoring.svg";
import Records from "../assets/CAMO/icon-camo-records.svg";
import TechSupport from "../assets/CAMO/icon-camo-tech-support.svg";
import BusinessAircraft from "../assets/CAMO/business-aircraft.svg";
import CommercialAircraft from "../assets/CAMO/airbus-maintenance.svg";
import FutureReady from "../assets/CAMO/ready-for-the-future.svg";
import DigitalTC from "../assets/CAMO/data-task-cards.svg";
import CAMP from "../assets/camp-logo2x.png";
import AMOS from "../assets/amos_logo.png";
import Centrik from "../assets/centrik_logo.png";
import Avianis from "../assets/Avianis.svg";
import Leon from "../assets/Leon.svg";
import Tick from "../assets/icon-tick.svg";
import TechCard from "./TechLog/techCard";
import Started from "./Layout/started";
import AlsoInterestedIn from "./Layout/alsoInterestedIn";
import Seamless from "./Layout/seamlessIntegration";
import * as pageStyles from "../pages/page.module.less";
import * as styles from "../pages/taskcards.module.less";

const ExperiencedCard = {
  img: HighlyExperienced,
  title: "Highly experienced",
  text: "We have a market-leading team of aviation experts covering the full spectrum of aviation maintenance and engineering support activities.",
};

const EASACard = {
  img: EASA,
  title: "Part-CAMO approved",
  text: "Recognized with both UK CAA and EASA approvals for compliance monitoring, maintenance management and continuing airworthiness oversight.",
};

const FutureCard = {
  img: FutureReady,
  title: "Ready for the future",
  text: "The deep integrations we have throughout our service portfolio ensure that we can offer a modern and future-proof service.",
};

const cardsArray = [ExperiencedCard, EASACard, FutureCard];

const StyledImage = styled.img`
  height: 35px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  span {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #242d41;
  }
  @media screen and (max-width: 576px) {
    justify-content: center;
  }
`;

const TinyText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(36, 45, 65, 0.7);
`;

const TopCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  padding-right: 30px;
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
    justify-content: center;
    padding-right: 0;
    text-align: center;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  ${(props) => props.left && "justify-content: left"}
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 150px;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

const SelectedTabText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  max-width: 1100px;
  margin-bottom: 60px;
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const Camo = (props) => {
  const cards = cardsArray.map((card, key) => (
    <Col key={key} md={4} className={`${pageStyles.coliPad}`}>
      <AnimationOnScroll
        animateOnce
        animateIn={
          key === 0
            ? "animate__fadeInLeft"
            : key === 1
            ? "animate__fadeInUp"
            : "animate__fadeInRight"
        }
        offset={0}
      >
        <TechCard noshadow img={card.img} title={card.title} text={card.text} />
      </AnimationOnScroll>
    </Col>
  ));

  const seamlessCards = [
    {
      img: CAMP,
      title: "CAMP",
    },
    {
      title: "And more",
    },
    {
      img: AMOS,
      title: "AMOS",
    },
    {
      img: Centrik,
      title: "SMS/QMS",
    },
    {
      img: Avianis,
      title: "Avianis",
    },
    {
      img: Leon,
      title: "Leon",
    },
  ];

  return (
    <>
      <h2>Continuing Airworthiness Management</h2>

      <SelectedTabText>
        Technical expertise and powerful analytics, combined with
        industry-leading maintenance control software. Enabling your operation
        to achieve more with less downtime and lower overall operating costs.
      </SelectedTabText>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={12} md={12}>
            <Row>{cards}</Row>
            <Row className={`${styles.titleRow} ${pageStyles.pageParentRow}`}>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInDown"
                    offset={0}
                  ></AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={`${styles.sectionTitle}`}>
                      Your experts in airworthiness
                    </h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={12} md={11}>
            <Row>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Maintenance} />
                      <span>Maintenance programmes</span>
                    </CardHeader>
                    <TinyText>
                      Combining analytics and maintenance experience to develop
                      maintenance programs that are optimised for your aircraft
                      and the operating environment in which you fly.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={FleetStatus} />
                      <span>Fleet planning and support</span>
                    </CardHeader>
                    <TinyText>
                      Offering medium and large fleet operators
                      reliability-centred maintenance solutions that maximize
                      operational safety and performance while reducing overall
                      costs.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={TechSupport} />
                      <span>Technical services</span>
                    </CardHeader>
                    <TinyText>
                      Guidance on AD/SBs, damage assessment and repairs,
                      modifications, defects and OEM liaison.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Records} />
                      <span>Aircraft records</span>
                    </CardHeader>
                    <TinyText>
                      Technical records audits and management of both fully
                      digital and paper-based records.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Monitoring} />
                      <span>Reliability monitoring and analytics</span>
                    </CardHeader>
                    <TinyText>
                      Monitoring and assessment of aircraft reliability data in
                      support of ETOPS approvals, corrosion control programs,
                      approach and navigation system monitoring.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
              <Col md={4} sm={6}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <TopCardWrapper>
                    <CardHeader>
                      <StyledImage src={Delivery} />
                      <span>Asset management</span>
                    </CardHeader>
                    <TinyText>
                      Aircraft acceptance, technical audits and mid-term/end of
                      lease inspections.
                    </TinyText>
                  </TopCardWrapper>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row className={`${styles.titleRow} ${pageStyles.pageParentRow}`}>
            <Col md={12}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInUp"
                offset={0}
              >
                <h4>
                  Helping our customers deliver operations from executive
                  transport, ultra-low-cost startups, high-value cargo transport
                  and every type of operation in between
                </h4>
              </AnimationOnScroll>
            </Col>
          </Row>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col
              md={6}
              className={`${pageStyles.coliPad} ${styles.mobileImage}`}
            >
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <img
                  alt="commerical"
                  src={CommercialAircraft}
                  className={styles.typesImageCommercial}
                />
              </AnimationOnScroll>
            </Col>
            <Col md={6} className={pageStyles.coliPad}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <h1 className={styles.aircraftTypeTitle}>
                  Commercial Aircraft
                </h1>
                <h4 className={`${styles.subTitle} ${styles.subTitleRight}`}>
                  Our unique team of aviation experts is recognized worldwide
                  for our experience supporting a wide variety of passenger,
                  cargo and charter airlines.
                </h4>
                <Row>
                  <Col className={styles.colAircraftTypes}>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Airbus</span>
                        <br />
                        A318, A319, A320, A321 and A330
                      </span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Boeing</span>
                        <br />
                        B737, 747, 757, 767, 777 and 787
                      </span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Regulatory Authorities</span>
                        <br />
                        ANAC, CASA, EASA, FAA, GCAA, TCCA, UK CAA
                      </span>
                    </div>
                  </Col>
                </Row>
              </AnimationOnScroll>
            </Col>
            <Col
              md={6}
              className={`${pageStyles.coliPad} ${styles.nonMobileImage}`}
            >
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <img
                  alt="commerical"
                  src={CommercialAircraft}
                  className={styles.typesImageCommercial}
                />
              </AnimationOnScroll>
            </Col>
          </Row>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowBusiness}`}
          >
            <Col md={6} className={pageStyles.coliPad}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <img
                  alt="business"
                  src={BusinessAircraft}
                  className={styles.typesImageBusiness}
                />
              </AnimationOnScroll>
            </Col>
            <Col md={6} className={pageStyles.coliPad}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <h1 className={styles.aircraftTypeTitle}>Business Aircraft</h1>
                <h4 className={`${styles.subTitle} ${styles.subTitleLeft}`}>
                  Our Business Aviation team has developed its skills supporting
                  not only our customers' aircraft but our own fleet. Combining
                  this with our commercial background, we deliver airline-level
                  safety, reliability and cost-effective support options.
                </h4>
                <Row>
                  <Col className={styles.colAircraftTypes}>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Embraer</span>
                        <br />
                        Phenom 100 & 300, Legacy 450 & 500, Praetor 500 & 600
                      </span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Cessna Citation</span>
                        <br />
                        CJ1/M2, CJ2, CJ3, CJ4, Excel, XLS
                      </span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Bombardier</span>
                        <br />
                        Challenger 300 & 350, Challenger 604 & 605, Global
                        Express
                      </span>
                    </div>
                    <div
                      className={`${styles.tickWrapper} ${styles.tickWrapperAC}`}
                    >
                      {tick}
                      <span>
                        <span>Regulatory Authorities</span>
                        <br />
                        ANAC, CASA, EASA, FAA, GCAA, TCCA, UK CAA
                      </span>
                    </div>
                  </Col>
                </Row>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Highly experienced with industry-leading aviation maintenance management software"
              text="Ensuring that we manage your assets in the most effective way for your organisation. "
              cards={seamlessCards}
            />
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={8} md={10}>
            <Row>
              <Col>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInDown"
                  offset={0}
                >
                  <h3 className={styles.title}>
                    Leading the industry forward through combined use of modern
                    processes and cutting-edge technology
                  </h3>
                </AnimationOnScroll>
              </Col>
            </Row>
            <CenteredDiv>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInUp"
                offset={0}
              >
                <h5 className={styles.subTitle}>
                  As a digital software provider, our Continuing Airworthiness
                  services leverage all of the benefits offered by digital
                  records and modern software. This ensures that our customers
                  not only have access to our portfolio of software products but
                  we also have the expertise in data analytics to provide
                  unparalleled insights into your aircraft.
                </h5>
              </AnimationOnScroll>
            </CenteredDiv>
          </Col>
          <Col lg={8} md={10} xs={10} className={styles.colFullImg}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
            >
              <img src={DigitalTC} alt="DigitalTaskCards" />
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        />
        <Started
          title="Let's start the conversation"
          text="Talk to our Continuing Airworthiness management team to find out how you can benefit from our expertise."
          hubSpotFormId="41a8067f-c272-4dd9-a386-00946023ec8d"
        />
      </Container>
      <AlsoInterestedIn page="camo" />
    </>
  );
};
export default Camo;
