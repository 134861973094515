import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import * as Scroll from "react-scroll";
import Mobile from "../assets/Services/icon-simple-mobile-app.svg";
import Metrics from "../assets/Services/icon-simple-metrics.svg";
import Partner from "../assets/Services/icon-simple-partnership.svg";
import Card1 from "../assets/Services/services-plane.jpg";
import Card2 from "../assets/Services/services-manuals.jpg";
import Card3 from "../assets/Services/services-engineer.jpg";
import Card4 from "../assets/Services/services-engine.jpg";
import Card5 from "../assets/Services/services-hangar.jpg";
import Tick from "../assets/icon-tick.svg";
import NewAOC from "../assets/Services/icon-services-new-aoc2x.png";
import Postholders from "../assets/Services/icon-services-postholders2x.png";
import Amendments from "../assets/Services/icon-services-amendments2x.png";
import FutureReady from "../assets/CAMO/ready-for-the-future.svg";
import EASA from "../assets/CAMO/easa-card.svg";
import Compliance from "../assets/Services/icon-services-regulatory-compliance.svg";
import HighlyExperienced from "../assets/CAMO/highly-experienced.svg";
import Revisions from "../assets/Services/icon-services-continual-revisions.svg";
import BrandedTemplates from "../assets/Services/icon-services-branded-templates.svg";
import DocumentPublishing from "../assets/Services/document-publishing.svg";
import FleetAssetManagement from "../assets/Services/fleet-asset-management.svg";
import AircraftAcquisition from "../assets/Services/icon-services-aircraft-acquisition.svg";
import AircraftDueDiligence from "../assets/Services/icon-services-aircraft-due-diligence.svg";
import AircraftPreDelivery from "../assets/Services/icon-services-pre-delivery-management.svg";
import Camo from "../components/camo";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Card from "../components/Services/Card";
import Started from "../components/Layout/started";

const { scroller } = Scroll;

const Background = styled.div`
  width: 100vw;
  background-image: linear-gradient(to top, #f8fcff, var(--white));
`;

const Background2 = styled.div`
  width: 100vw;
  background-image: linear-gradient(to top, #f8fcff, var(--white) 33%);
`;

const QualityWrapper = styled.div`
  padding: 100px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  color: #242d41;
  & h2 {
    grid-column: 1/4;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    padding: 40px 0;
    & h2 {
      grid-column: auto;
      font-size: 28px;
    }
  }
`;

const ConvoWrapper = styled.div`
  grid-row: 1/4;
  grid-column: 4/5;
  @media screen and (max-width: 576px) {
    grid-row: auto;
    grid-column: auto;
  }
  @media screen and (max-width: 820px) {
    margin-top: 90px;
  }
`;

const QualityText = styled.p`
  grid-row: 2/3;
  grid-column: 1/4;
  font-size: 18px;
  line-height: 1.56;
  color: rgba(36, 45, 65, 0.9);
  padding-right: 140px;
  padding-bottom: 40px;
  @media screen and (max-width: 991px) {
    padding-right: 70px;
  }
  @media screen and (max-width: 576px) {
    grid-row: auto;
    grid-column: auto;
    padding-right: 0;
    font-size: 16px;
  }
`;

const SectorDiv = styled.div`
  padding-right: 24px;
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const SectorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 24px;
`;

const SectorText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.8);
`;

const ConvoHeader = styled.h5`
  font-weight: 600;
  margin-top: 24px;
`;

const LineBreak = styled.div`
  height: 1px;
  background-color: #eeeff3;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const Contact = styled.div`
  margin: 28px 0 0;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #ffffff;
`;

const Email = styled.h6`
  line-height: 1.5;
  color: #126fd6;
`;

const CardsWrapper = styled.div`
  margin: 100px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    margin: 60px 0;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    margin: 40px 0;
  }
`;

const TickDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
`;
const TickImg = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 16px;
`;

const TickText = styled.span`
  color: rgba(36, 45, 65, 0.7);
  @media screen and (max-width: 451px) {
    text-align: left;
  }
`;

const WorldWideDiv = styled.div`
  margin: 150px 0;
  display: flex;
  color: rgba(36, 45, 65, 0.9);
  @media screen and (max-width: 991px) {
    margin: 60px 0;
    flex-direction: column;
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    margin: 40px 0;
    flex-direction: column;
    & h2 {
      font-size: 22px;
    }
  }
`;

const WorldWideText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  padding-bottom: 20px;
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const EngineerImage = styled.img`
  max-height: 400px;
  border-radius: 15px;
  @media screen and (max-width: 991px) {
    max-height: fit-content;
  }
  @media screen and (max-width: 820px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  flex: 8;
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`;

const TextWrapper = styled.div`
  flex: 10;
  @media screen and (max-width: 991px) {
    flex: 1;
    margin-top: 60px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
    margin-top: 40px;
  }
`;

const TabSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #eeeff3;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const Tab = styled.div`
  text-align: center;
  border-bottom: ${({ selected }) => (selected ? "1px solid #1e5bb5" : "none")};
  color: ${({ selected }) => (selected ? "#1e5bb5" : "#363f41")};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  cursor: pointer;
  padding: 0px 20px 30px 20px;
  transition: 0.4s all;
  &:hover {
    color: #1e5bb5;
  }
  @media screen and (max-width: 991px) {
    font-size: 16px;
    padding: 15px 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
    padding: 15px 10px;
  }
`;

const SelectedTab = styled.div`
  margin-top: 80px;
  color: rgba(36, 45, 65, 0.9);
  padding-bottom: 170px;
  @media screen and (max-width: 991px) {
    margin-top: 60px;
    padding-bottom: 120px;
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    margin-top: 40px;
    padding-bottom: 80px;
    & h2 {
      font-size: 22px;
    }
  }
`;

const SelectedTabText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: ${({ reduceMargin }) => (reduceMargin ? "28px" : "80px")};
  max-width: 1100px;
  @media screen and (max-width: 576px) {
    font-size: 16px;
    text-align: middle;
  }
`;

const TabCardParagraphWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const SelectedTabTextWrapper = styled.div`
  flex: 1;
`;

const SelectedTabImageWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin-bottom: 30px;
  & img {
    max-width: 490px;
    max-height: 340px;
  }
  @media screen and (max-width: 576px) {
    font-size: 16px;
    text-align: middle;
  }
`;

const TabCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  & img {
    height: 120px;
    width: 120px;
    margin-bottom: 36px;
  }
  & h5 {
    color: #1e5bb5;
    min-height: ${({ height }) => (height ? `${height}px` : "auto")};
  }
  & p {
    font-size: 16px;
    font-weight: light;
  }
  & ${TickImg} {
    height: 25px;
    width: 25px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    text-align: center;
    & h2 {
      font-size: 22px;
    }
  }
`;

const Services = (props) => {
  const sectors = [
    {
      img: Mobile,
      title: "Use of technology",
      text: "We continue to invest in the latest technology to provide efficient exchange of information and reporting, and to maintain the quality of your documentation.",
    },
    {
      img: Partner,
      title: "Cost leadership",
      text: "We achieve cost leadership, demonstrated by our adoption by LCCs and ULCCs, through the scale of our expertise, tools, and passion.",
    },
    {
      img: Metrics,
      title: "Focus on value",
      text: "We provide additional benefit to you through reduced conversion time, strong workflows, ability to work with varied data types and content production for all output channels",
    },
  ];
  const cards = [
    {
      key: "CAMO",
      img: Card4,
      title: "Continuing Airworthiness Management",
      cardTitle: "Continuing Airworthiness Management",
      cardText:
        "Technical expertise and powerful analytics, combined with industry-leading maintenance control software. Enabling your operation to achieve more with less downtime and lower overall operating costs",
      sections: [
        {
          img: HighlyExperienced,
          title: "Highly experienced",
          text: "We have a market-leading team of aviation experts covering the full spectrum of aviation maintenance and engineering support activities.",
        },
        {
          img: EASA,
          title: "Part-M Subpart G approved",
          text: "Recognized with both UK CAA and EASA approvals for compliance monitoring, maintenance management and continuing airworthiness oversight.",
        },
        {
          img: FutureReady,
          title: "Ready for the future",
          text: "The deep integrations we have throughout our service portfolio ensure that we can offer a modern and future-proof service.",
        },
      ],
    },
    {
      key: "AOC",
      img: Card1,
      title: "AOC Services",
      cardTitle: "AOC Services",
      cardText:
        "Our team of operational experts, nominated persons (post holders) and former regulators can manage the entire process from instruction to issue of the certificate. When required we are able to continue our services post issue of AOC.",
      sections: [
        {
          img: NewAOC,
          title: "New AOC",
          text: "Starting a new AOC process can be an overwhelming journey. Our proven method supported by our state of the art software and our team’s expertise will ensure that the process is done in the most time efficient and cost effective manner. We will work hand in hand with your Management team to draft the relevant processes and procedures in accordance with its Standard Operating Procedures (SOP’s) and the requirements set by the issuing authority.",
        },
        {
          img: Amendments,
          title: "Amendments",
          text: "Regulations, policies and procedures change on a regular basis. You might also be facing a new challenge to obtain an approval that you don’t have yet. In all those cases, our team is ready to assist and amend your documentation where needed, using our great experience of helping customers to meet regulatory requirements.",
        },
        {
          img: Postholders,
          title: "Postholders",
          text: "Our team of experts can assist in any operational department on an interim or permanent basis, based on your needs. Staffing an entire organisation with the right people is a challenging task, and we will be happy to assist you.",
        },
      ],
    },
    {
      key: "Documents",
      img: Card2,
      title: "Document Publishing",
      cardImg: DocumentPublishing,
      cardTitle: "Document Publishing",
      cardText: `We can manage your regulatory documents in our advanced XML publishing system. All the manuals that we manage for clients are drafted in electronic format and can be exported with hyperlinks to allow quick and easy use on an Electronic Flight Bag or PC.`,
      secondParagraph:
        "We use a publishing system that allows online review and editing of documents. All changes are tracked and can be approved online. It can export to all common formats such as Word, HTML and PDF. It supports common branding of manuals and other documents.",
      sections: [
        {
          img: Compliance,
          title: "Regulatory compliance",
          text: "Creating original regulatory compliant documents for all operators and aerodromes",
        },
        {
          img: Revisions,
          title: "Continual revisions",
          text: "Manual Revision Service (MRS) – regular updates to ensure continuing compliance",
        },
        {
          img: BrandedTemplates,
          title: "Branded templates",
          text: "Documents compliant with your organization’s corporate brand",
        },
      ],
    },
    {
      key: "Fleet",
      img: Card3,
      title: "Fleet Asset Management",
      cardTitle: "Fleet Asset Management",
      cardImg: FleetAssetManagement,
      cardText:
        "Our team of expert Asset Managers have many years’ experience in the global airline and lessor sectors. We can support clients with a complete ‘lifecycle’ of services that manage and maintain the asset integrity and value, tailored to the operational and geographical demands of the client.",
      secondParagraph:
        "TrustFlight’s Asset Management services provide peace of mind to asset owners/operators ensuring costs are controlled, lease and acquisition projects can be delivered on time and on budget. They include:",
      sections: [
        {
          img: AircraftAcquisition,
          title: "Aircraft acquisition",
          points: [
            "Perform a market review of the aircraft available and preferred by the client",
            "Benefit from working with our closely connected OEMs, Owners and Lessors",
            "Develop and manage any RFP process through to short listing of candidate aircraft",
            "Provide the pre-purchase inspections of short-listed aircraft",
            "Support the legal and contractual activities to secure the selected aircraft",
          ],
        },
        {
          img: AircraftDueDiligence,
          title:
            "Aircraft due diligence including config evaluation and optimisation",
          points: [
            "Carry out detailed buy side inspections of the selected aircraft and their records",
            "Perform analysis and preparation of the aircraft and records for the registry/operational jurisdiction taking account of any ‘first of type’ concerns",
            "Evaluate the technical equipment and configuration for the expected operation",
            "Provide a specification to the airline commercial team for marketing purposes (operational performance and payload criteria etc)",
            "Compile the specification and certification requirements of any modification packages including a cargo conversion if required",
            "Provide budgetary guidance and cost management support",
          ],
        },
        {
          img: AircraftPreDelivery,
          title: "Management of pre-delivery maintenance and certification",
          points: [
            "Provide required support and liaison with existing owner/lessor to select a suitable maintenance provider (MRO) capable of carrying out the required maintenance  ",
            "Manage the MRO RFP process to ensure a competitive and cost-effective solution is secured",
            "Deliver project management of the maintenance check itself including all stakeholders and third-party suppliers",
            "Ensure state of registry is informed and involved where they wish to be",
            "Ensure the best outcomes are achieved in terms of forward planning and available time to next maintenance checks (including engines and major components)",
            "Provide budgetary guidance and cost management support",
          ],
        },
      ],
    },
  ];

  const [selectedTab, setSelectedTab] = useState(cards[0]);
  const [passedTab, setPassedTab] = useState(null);

  const scrollToTabs = () => {
    const { hash } = window.location;
    if (!hash) {
      return;
    }
    const selectedTabIndex =
      cards.findIndex(
        (card) => card.key.toLowerCase() === hash.slice(1).toLowerCase()
      ) || 0;
    setSelectedTab(cards[selectedTabIndex]);
    scroller.scrollTo("tabSection", {
      duration: 500,
      smooth: true,
      offset: -100,
    });
  };

  useEffect(() => {
    if (passedTab) {
      if (passedTab === "AOC") {
        window.location.hash = `aoc`;
      } else if (passedTab === "doc") {
        window.location.hash = `documents`;
      } else if (passedTab === "fleet") {
        window.location.hash = `fleet`;
      }
      scrollToTabs();
      setPassedTab(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedTab]);

  useEffect(() => {
    if (props.location?.state?.from) {
      setPassedTab(props.location?.state?.from);
    }
  }, [props.location?.state?.from]);

  useEffect(() => {
    if (window.location && window.location.hash) scrollToTabs();
    window.addEventListener("hashchange", scrollToTabs);
    return () => window.removeEventListener("hashchange", scrollToTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Seo />
      <PageHeader
        header="Aviation Services"
        subHeader="Helping our global aviation partners achieve their aviation vision from start to finish with solutions for project management, regulatory approval, continuing airworthiness and ongoing asset management."
        mediumHeader
        longTag
      />

      <Background>
        <Container>
          <QualityWrapper>
            <h2>Delivering the quality and service you deserve</h2>
            <QualityText>
              Our team has roots in all aspects of aviation operational
              leadership. We have supported and actively worked in all areas of
              the aviation industry from line flying and engineering design to
              maintenance and quality oversight. We have delivered safe,
              reliable, cost-effective aviation solutions that allow our
              customers to scale their operations from small beginnings to large
              fleet operations.
            </QualityText>

            {sectors.map((sector) => (
              <SectorDiv key={sector.title}>
                <img src={sector.img} alt={"tech-logo"}></img>
                <SectorTitle>{sector.title}</SectorTitle>
                <SectorText>{sector.text}</SectorText>
              </SectorDiv>
            ))}
            <ConvoWrapper>
              <ConvoHeader>Start the conversation</ConvoHeader>
              <LineBreak />
              <Contact>
                <p>
                  If you require more information or need any specialist advice
                  then get in touch with us
                </p>
                <Email>
                  <a href={"mailto:info@trustflight.com"}>
                    info@trustflight.com
                  </a>
                </Email>
              </Contact>
            </ConvoWrapper>
          </QualityWrapper>
        </Container>
      </Background>
      <Container>
        <CardsWrapper>
          {cards.map((card) => (
            <Card
              key={card.key}
              href={`#${card.key.toLowerCase()}`}
              title={card.title}
              img={card.img}
            />
          ))}
        </CardsWrapper>
        <WorldWideDiv>
          <ImageWrapper>
            <EngineerImage src={Card5} />
          </ImageWrapper>
          <TextWrapper>
            <h2>Worldwide experience</h2>
            <WorldWideText>
              We have the experience and skills to efficiently support all
              aircraft types and operators globally.
            </WorldWideText>
            <div>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Over 60 subject matter experts including Flight and Ground
                  Ops, Airworthiness, Safety and Compliance
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Technical Publishing experts versed in XML content management
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Operational experience across Commercial Operations and
                  Business Aviation for both fixed and rotary wing operators
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Supporting a wide range of 50+ aircraft types, from the
                  Gippsland Airvan to the G650 and onward, including ATRs and
                  the A320, B737 through to the B787, A340, B747
                </TickText>
              </TickDiv>
              <TickDiv>
                <TickImg src={Tick} />
                <TickText>
                  Using our own Centrik SMS system we can ensure you get the
                  maximum value and effect from your Safety and Quality teams
                </TickText>
              </TickDiv>
            </div>
          </TextWrapper>
        </WorldWideDiv>
      </Container>
      <Background2>
        <Container>
          <TabSection name="tabSection">
            {cards.map((card) => (
              <Tab
                key={card.key}
                onClick={() =>
                  (window.location.hash = `#${card.key.toLowerCase()}`)
                }
                selected={card.key === selectedTab?.key}
              >
                {card.title}
              </Tab>
            ))}
          </TabSection>
          <SelectedTab>
            {selectedTab?.key === "CAMO" ? (
              <Camo />
            ) : (
              <React.Fragment key={selectedTab?.key}>
                <h2>{selectedTab?.cardTitle}</h2>
                <TabCardParagraphWrapper>
                  <SelectedTabTextWrapper>
                    <SelectedTabText
                      reduceMargin={!!selectedTab?.secondParagraph}
                    >
                      {selectedTab?.cardText}
                    </SelectedTabText>
                    {selectedTab?.secondParagraph && (
                      <SelectedTabText>
                        {selectedTab?.secondParagraph}
                      </SelectedTabText>
                    )}
                  </SelectedTabTextWrapper>
                  {selectedTab?.cardImg && (
                    <SelectedTabImageWrapper>
                      <img src={selectedTab?.cardImg} alt="card" />
                    </SelectedTabImageWrapper>
                  )}
                </TabCardParagraphWrapper>
                <TabCardWrapper
                  height={selectedTab?.key === "Fleet" ? 75 : null}
                >
                  {selectedTab?.sections.map((section) => (
                    <div key={section.title}>
                      <img src={section.img} alt="section" />
                      <h5>{section.title}</h5>
                      {section?.points ? (
                        <>
                          {section?.points.map((point) => {
                            return (
                              <TickDiv>
                                <TickImg src={Tick} />
                                <TickText>{point}</TickText>
                              </TickDiv>
                            );
                          })}
                        </>
                      ) : (
                        <p>{section.text}</p>
                      )}
                    </div>
                  ))}
                </TabCardWrapper>
                <Started
                  title="Let's start the conversation"
                  text="Talk to our services team to find out how you can benefit from our expertise."
                  hubSpotFormId="41a8067f-c272-4dd9-a386-00946023ec8d"
                />
              </React.Fragment>
            )}
          </SelectedTab>
        </Container>
      </Background2>
    </Layout>
  );
};
export default Services;
